
          export const options = {
  "includeNodeModules": false,
  "useFetchPolyfill": false,
  "options": {
    "method": "get"
  },
  "clients": {
    "default": {
      "endpoint": "https://craft.library.ucla.edu/api",
      "options": {}
    }
  }
}
        