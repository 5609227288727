import { default as _91_46_46_46slug_93N7gvdW4HfVMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/[...slug].vue?macro=true";
import { default as index3FK79nSqPYMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/about/jobs/staff-academic-jobs/index.vue?macro=true";
import { default as _91slug_93sxJ5sIetp9Meta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/about/news/[slug].vue?macro=true";
import { default as indexHXjXCGi0JwMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/about/news/index.vue?macro=true";
import { default as _91slug_932KVK1XFe3aMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/about/policies/[slug].vue?macro=true";
import { default as indexbFcoZHTSEoMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/about/policies/index.vue?macro=true";
import { default as _91slug_93BTscUbiNrnMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/about/programs/[slug].vue?macro=true";
import { default as indexsDiBM2rITXMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/about/programs/index.vue?macro=true";
import { default as indexpy2RvtFv4cMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/about/reports/index.vue?macro=true";
import { default as _91slug_93tHQVWsQVjeMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/about/staff/[slug].vue?macro=true";
import { default as indexqc9yhK8Gt4Meta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/about/staff/index.vue?macro=true";
import { default as indexTvcym79HWvMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/about/student-opportunities/index.vue?macro=true";
import { default as index40OOzBfHoBMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/collections/access/index.vue?macro=true";
import { default as _91slug_93vY2gCTLv4AMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/collections/explore/[slug].vue?macro=true";
import { default as index5BjM344LVZMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/collections/explore/index.vue?macro=true";
import { default as indexc9RbTvEsmXMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/collections/index.vue?macro=true";
import { default as _91slug_93H8jNQAq4FEMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/give/endowments/[slug].vue?macro=true";
import { default as indexjQqTqP5XAPMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/give/endowments/index.vue?macro=true";
import { default as _91slug_93iSytsKRa5uMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/help/[slug].vue?macro=true";
import { default as _91slug_93hgQfJP7IfIMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/help/services-resources/[slug].vue?macro=true";
import { default as indexvzMLqJPVSpMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/help/services-resources/ask-us/index.vue?macro=true";
import { default as indexAlAN8DYGXkMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/help/services-resources/index.vue?macro=true";
import { default as _91slug_93lFHnbcW0b9Meta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/impact/[year]/[slug].vue?macro=true";
import { default as indexRPHHqpOVbeMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/impact/[year]/index.vue?macro=true";
import { default as indexxnKvM5bxVfMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/impact/index.vue?macro=true";
import { default as indexxJ8MbgiLYeMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/index.vue?macro=true";
import { default as indexRuHoMYOvemMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/search-site/index.vue?macro=true";
import { default as _91slug_93dFRPFHxcLJMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/visit/events-exhibitions/[slug].vue?macro=true";
import { default as indexd8bsHCwAbrMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/visit/events-exhibitions/index.vue?macro=true";
import { default as _91slug_93Gdr23OrfoeMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/visit/locations/[slug].vue?macro=true";
import { default as indexJZWiFOCav6Meta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/pages/visit/locations/index.vue?macro=true";
import { default as component_45stubPOZXPiy4tdMeta } from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.54_eslint@8.57.1_sass@1.79.3_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubPOZXPiy4td } from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.54_eslint@8.57.1_sass@1.79.3_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/[...slug].vue")
  },
  {
    name: "about-jobs-staff-academic-jobs",
    path: "/about/jobs/staff-academic-jobs",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/about/jobs/staff-academic-jobs/index.vue")
  },
  {
    name: "about-news-slug",
    path: "/about/news/:slug()",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/about/news/[slug].vue")
  },
  {
    name: "about-news",
    path: "/about/news",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/about/news/index.vue")
  },
  {
    name: "about-policies-slug",
    path: "/about/policies/:slug()",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/about/policies/[slug].vue")
  },
  {
    name: "about-policies",
    path: "/about/policies",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/about/policies/index.vue")
  },
  {
    name: "about-programs-slug",
    path: "/about/programs/:slug()",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/about/programs/[slug].vue")
  },
  {
    name: "about-programs",
    path: "/about/programs",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/about/programs/index.vue")
  },
  {
    name: "about-reports",
    path: "/about/reports",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/about/reports/index.vue")
  },
  {
    name: "about-staff-slug",
    path: "/about/staff/:slug()",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/about/staff/[slug].vue")
  },
  {
    name: "about-staff",
    path: "/about/staff",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/about/staff/index.vue")
  },
  {
    name: "about-student-opportunities",
    path: "/about/student-opportunities",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/about/student-opportunities/index.vue")
  },
  {
    name: "collections-access",
    path: "/collections/access",
    meta: index40OOzBfHoBMeta || {},
    alias: ["/listing-collections/access/"],
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/collections/access/index.vue")
  },
  {
    name: "collections-explore-slug",
    path: "/collections/explore/:slug()",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/collections/explore/[slug].vue")
  },
  {
    name: "collections-explore",
    path: "/collections/explore",
    meta: index5BjM344LVZMeta || {},
    alias: ["/listing-collections/explore/"],
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/collections/explore/index.vue")
  },
  {
    name: "collections",
    path: "/collections",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/collections/index.vue")
  },
  {
    name: "give-endowments-slug",
    path: "/give/endowments/:slug()",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/give/endowments/[slug].vue")
  },
  {
    name: "give-endowments",
    path: "/give/endowments",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/give/endowments/index.vue")
  },
  {
    name: "help-slug",
    path: "/help/:slug()",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/help/[slug].vue")
  },
  {
    name: "help-services-resources-slug",
    path: "/help/services-resources/:slug()",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/help/services-resources/[slug].vue")
  },
  {
    name: "help-services-resources-ask-us",
    path: "/help/services-resources/ask-us",
    meta: indexvzMLqJPVSpMeta || {},
    alias: ["/help/"],
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/help/services-resources/ask-us/index.vue")
  },
  {
    name: "help-services-resources",
    path: "/help/services-resources",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/help/services-resources/index.vue")
  },
  {
    name: "impact-year-slug",
    path: "/impact/:year()/:slug()",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/impact/[year]/[slug].vue")
  },
  {
    name: "impact-year",
    path: "/impact/:year()",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/impact/[year]/index.vue")
  },
  {
    name: "impact",
    path: "/impact",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/impact/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/index.vue")
  },
  {
    name: "search-site",
    path: "/search-site",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/search-site/index.vue")
  },
  {
    name: "visit-events-exhibitions-slug",
    path: "/visit/events-exhibitions/:slug()",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/visit/events-exhibitions/[slug].vue")
  },
  {
    name: "visit-events-exhibitions",
    path: "/visit/events-exhibitions",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/visit/events-exhibitions/index.vue")
  },
  {
    name: "visit-locations-slug",
    path: "/visit/locations/:slug()",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/visit/locations/[slug].vue")
  },
  {
    name: "visit-locations",
    path: "/visit/locations",
    component: () => import("/home/runner/work/library-website-nuxt/library-website-nuxt/pages/visit/locations/index.vue")
  },
  {
    name: component_45stubPOZXPiy4tdMeta?.name,
    path: "/impact/all",
    component: component_45stubPOZXPiy4td
  }
]