import routerOptions0 from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.54_eslint@8.57.1_sass@1.79.3_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/home/runner/work/library-website-nuxt/library-website-nuxt/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}