import revive_payload_client_mf0uB3ouRB from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.54_eslint@8.57.1_sass@1.79.3_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_vwxL72iO3A from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.54_eslint@8.57.1_sass@1.79.3_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_5sH10g5pLv from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.54_eslint@8.57.1_sass@1.79.3_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_vXYv7r2VBI from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt-site-config@2.2.18_vite@5.4.8_vue@3.5.10/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_8fQ2G0m7VF from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.54_eslint@8.57.1_sass@1.79.3_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_lSr43Yh0Vs from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.54_eslint@8.57.1_sass@1.79.3_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_7k0gh1Hwcd from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.54_eslint@8.57.1_sass@1.79.3_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_aSnGOGgil0 from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.54_eslint@8.57.1_sass@1.79.3_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_GQ0LGze6CG from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/@pinia+nuxt@0.5.4_typescript@5.6.2_vue@3.5.10/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/library-website-nuxt/library-website-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_YsSQYjsncJ from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.13.2_@types+node@18.19.54_eslint@8.57.1_sass@1.79.3_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tGefRoZkUQ from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.13.2_vue@3.5.10/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_3W1kP6H59v from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt-graphql-request@7.0.5_graphql@16.9.0/node_modules/nuxt-graphql-request/dist/runtime/plugin.mjs";
import add_library_website_vue_components_yLAStJOsBJ from "/home/runner/work/library-website-nuxt/library-website-nuxt/plugins/add-library-website-vue-components.js";
import add_vue_skip_to_TpqMnYriJH from "/home/runner/work/library-website-nuxt/library-website-nuxt/plugins/add-vue-skip-to.js";
import craft_layout_data_client_tC01UjHDpi from "/home/runner/work/library-website-nuxt/library-website-nuxt/plugins/craft-layout-data.client.js";
import craft_xgLlYngLwv from "/home/runner/work/library-website-nuxt/library-website-nuxt/plugins/craft.js";
import data_api_6nrdODAL7t from "/home/runner/work/library-website-nuxt/library-website-nuxt/plugins/data-api.js";
import get_headers_client_gEno0Gq67v from "/home/runner/work/library-website-nuxt/library-website-nuxt/plugins/get-headers.client.js";
import scrape_formid_client_L5cBRTlafH from "/home/runner/work/library-website-nuxt/library-website-nuxt/plugins/scrape-formid.client.js";
import update_library_alert_client_kn0IiQ5VL4 from "/home/runner/work/library-website-nuxt/library-website-nuxt/plugins/update-library-alert.client.js";
export default [
  revive_payload_client_mf0uB3ouRB,
  unhead_vwxL72iO3A,
  router_5sH10g5pLv,
  _0_siteConfig_vXYv7r2VBI,
  payload_client_8fQ2G0m7VF,
  navigation_repaint_client_lSr43Yh0Vs,
  check_outdated_build_client_7k0gh1Hwcd,
  chunk_reload_client_aSnGOGgil0,
  plugin_vue3_GQ0LGze6CG,
  components_plugin_KR1HBZs4kY,
  prefetch_client_YsSQYjsncJ,
  plugin_tGefRoZkUQ,
  plugin_3W1kP6H59v,
  add_library_website_vue_components_yLAStJOsBJ,
  add_vue_skip_to_TpqMnYriJH,
  craft_layout_data_client_tC01UjHDpi,
  craft_xgLlYngLwv,
  data_api_6nrdODAL7t,
  get_headers_client_gEno0Gq67v,
  scrape_formid_client_L5cBRTlafH,
  update_library_alert_client_kn0IiQ5VL4
]